import { parsePlainDate } from '@packfleet/datetime';
import * as Checkbox from '@radix-ui/react-checkbox';
import cs from 'classnames';
import { capitalize } from 'lodash';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

import CollectionLocationsSelector from '@/components/collections/CollectionLocationsSelector';
import { Icons } from '@/components/navbar/Icons';
import Toggle from '@/components/toggle/Toggle';
import {
  CollectionLocationFragment,
  ExternalCarrierPayloadFragment,
  OrderDirection,
  ShipmentOrderField,
} from '@/generated/graphql';
import { useOrganizationTimezone } from '@/hooks/timezone';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useListCarriers } from '@/hooks/useListCarriers';
import { formatFriendlyDate } from '@/utilities/date';
import {
  Popover,
  PopoverContent,
  PopoverFieldGroup,
  PopoverTrigger,
} from '@components/popover/Popover';
import {
  Button,
  RadioGroupItem,
  RadioGroupRoot,
  Shipment,
} from '@packfleet/ui';
import { DateFilter } from './DateFilter';
import { useCollectionLocations } from './useCollectionLocations';
import { useSortShipmentsState } from './useSortShipmentsState';

export type CarrierCode = ReturnType<
  typeof useListCarriers
>['carriers'][0]['carrierCode'];

type CollectionLocations = ReturnType<
  typeof useCollectionLocations
>['collectionLocations'];

const formatSelectedFilterCarriers = (
  filterCarriers: Set<CarrierCode>,
  availableCarriers: ExternalCarrierPayloadFragment[],
) => {
  if (filterCarriers.size === 0) return '';

  const carriersByCode = Object.fromEntries(
    availableCarriers.map((c) => [c.carrierCode, c.name]),
  );
  const carrierNames = Array.from(filterCarriers).map(
    (c) => carriersByCode[c] ?? capitalize(c),
  );
  const lastCarrier = carrierNames.pop();

  return carrierNames.length
    ? `${carrierNames.join(', ')} or ${lastCarrier}`
    : lastCarrier;
};

const ORDER_BY_OPTIONS = [
  {
    label: 'Collection date',
    value: ShipmentOrderField.CollectionDate,
  },
  {
    label: 'Date created',
    value: ShipmentOrderField.CreatedAt,
  },
  {
    label: 'Reference',
    value: ShipmentOrderField.ExternalReference,
  },
  {
    label: 'Customer name',
    value: ShipmentOrderField.DeliveryLocationName,
  },
  {
    label: 'Postcode',
    value: ShipmentOrderField.PostCode,
  },
];

const ORDER_DIRECTION_OPTIONS = [OrderDirection.Asc, OrderDirection.Desc];

const SortShipmentsContext = React.createContext<{
  orderByField: ShipmentOrderField;
  orderByDirection: OrderDirection;
  setOrderByField: (value: ShipmentOrderField) => void;
  setOrderByDirection: (value: OrderDirection) => void;
  filterShipmentsWithoutLabels: boolean;
  setFilterShipmentsWithoutLabels: (value: boolean) => void;
  carriersFilter: Set<CarrierCode>;
  setCarriersFilter: (value: Set<CarrierCode>) => void;
  createdAt?: string;
  setCreatedAt: (value: string | undefined) => void;
  collectionDate?: string;
  setCollectionDate: (value: string | undefined) => void;
  deliveryDate?: string;
  setDeliveryDate: (value: string | undefined) => void;
  numFiltersEnabled: number;
  emptyState: string;
  filter?: string;
  setFilter: (filter: string) => void;
  carriers: ExternalCarrierPayloadFragment[];
  resetFilters: () => void;
  selectedLocation: CollectionLocationFragment | null;
  onSelectLocation: (
    selectedLocation: CollectionLocationFragment | null,
  ) => void;
  collectionLocations: CollectionLocations;
}>({
  orderByField: ShipmentOrderField.CollectionDate,
  setOrderByField: () => null,
  orderByDirection: OrderDirection.Desc,
  setOrderByDirection: () => null,
  createdAt: undefined,
  setCreatedAt: () => null,
  collectionDate: undefined,
  setCollectionDate: () => null,
  deliveryDate: undefined,
  setDeliveryDate: () => null,
  filterShipmentsWithoutLabels: false,
  setFilterShipmentsWithoutLabels: () => null,
  carriersFilter: new Set(),
  setCarriersFilter: () => null,
  numFiltersEnabled: 0,
  emptyState: '',
  filter: undefined,
  setFilter: () => null,
  carriers: [],
  resetFilters: () => null,
  selectedLocation: null,
  onSelectLocation: () => null,
  collectionLocations: [],
});

export const SortShipmentsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const timezone = useOrganizationTimezone();
  const router = useRouter();
  const { carriers } = useListCarriers();

  const { locationId } = router.query;

  const { selectedLocation, onSelectLocation, collectionLocations } =
    useCollectionLocations();

  const {
    orderByField,
    setOrderByField,
    orderByDirection,
    setOrderByDirection,
    filterShipmentsWithoutLabels,
    setFilterShipmentsWithoutLabels,
    carriersFilter,
    setCarriersFilter,
    createdAt,
    setCreatedAt,
    collectionDate,
    setCollectionDate,
    deliveryDate,
    setDeliveryDate,
    filter,
    setFilter,
    resetFilters,
  } = useSortShipmentsState();

  const numFiltersEnabled = [
    filterShipmentsWithoutLabels,
    carriersFilter.size !== carriers.length,
    locationId,
    collectionDate,
    createdAt,
    deliveryDate,
  ].filter(Boolean).length;

  let emptyState = [
    'We couldn’t find any shipments ',
    filterShipmentsWithoutLabels ? 'with labels not yet printed' : '',
    createdAt
      ? ` imported on ${formatFriendlyDate(
          parsePlainDate(createdAt),
          timezone,
        )}`
      : '',
    collectionDate
      ? ` with a collection date of ${formatFriendlyDate(
          parsePlainDate(collectionDate),
          timezone,
        )}`
      : '',
    deliveryDate
      ? ` with a delivery date of ${formatFriendlyDate(
          parsePlainDate(deliveryDate),
          timezone,
        )}`
      : '',
    carriersFilter.size
      ? ` delivered by ${formatSelectedFilterCarriers(
          carriersFilter,
          carriers,
        )}`
      : '',
  ]
    .join('')
    .trim();

  if (!carriersFilter.size)
    emptyState =
      'No shipments to display. Please adjust your selected filters.';

  return (
    <SortShipmentsContext.Provider
      value={{
        orderByField,
        setOrderByField,
        orderByDirection,
        setOrderByDirection,
        filterShipmentsWithoutLabels,
        setFilterShipmentsWithoutLabels,
        carriersFilter,
        setCarriersFilter,
        numFiltersEnabled,
        createdAt,
        setCreatedAt,
        collectionDate,
        setCollectionDate,
        deliveryDate,
        setDeliveryDate,
        emptyState,
        filter,
        setFilter,
        carriers,
        resetFilters,
        selectedLocation,
        onSelectLocation,
        collectionLocations,
      }}
    >
      {children}
    </SortShipmentsContext.Provider>
  );
};

export const useSortShipments = () => {
  const context = useContext(SortShipmentsContext);

  return context;
};

const getDirectionLabelForField = (
  field: ShipmentOrderField,
  direction: OrderDirection,
) => {
  const isAscending = direction === OrderDirection.Asc;

  switch (field) {
    case ShipmentOrderField.CollectionDate:
    case ShipmentOrderField.CreatedAt:
      return isAscending ? 'Oldest first' : 'Newest first';
    case ShipmentOrderField.PostCode:
    case ShipmentOrderField.ExternalReference:
    case ShipmentOrderField.DeliveryLocationName:
      return isAscending ? 'A-Z' : 'Z-A';
    default:
      return isAscending ? 'Ascending' : 'Descending';
  }
};

const FilterShipmentsControls = () => {
  const {
    setFilterShipmentsWithoutLabels,
    filterShipmentsWithoutLabels,
    carriersFilter,
    setCarriersFilter,
    numFiltersEnabled,
    createdAt,
    setCreatedAt,
    collectionDate,
    setCollectionDate,
    deliveryDate,
    setDeliveryDate,
    carriers,
    resetFilters,
    selectedLocation,
    onSelectLocation,
    collectionLocations,
  } = useSortShipments();

  const router = useRouter();
  const organization = useCurrentUser()?.organization;
  const urlParams = { ...router.query };

  // Clear initially selected shipments when filtering
  urlParams.selectedShipments = undefined;

  return (
    <Popover>
      <PopoverTrigger className="w-full border-transparent p-0">
        <Button
          className="relative w-full text-ellipsis whitespace-nowrap rounded-full pl-5 pr-4"
          s="smaller"
          color="neutral"
          mode="text"
          icon={Icons.filter}
          onClick={() => undefined}
        >
          <span className="flex gap-2 pl-2">
            Filter
            {numFiltersEnabled ? (
              <span className="absolute right-0 top-0 flex h-5 w-5 translate-x-1 scale-75 items-center justify-center rounded-full bg-primaryInverted text-xs text-primaryInverted">
                {numFiltersEnabled}
              </span>
            ) : null}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent variant="dropdown" className="mr-8 min-w-[27rem]">
        <div className="flex justify-between">
          <div className="mb-6">
            <PopoverFieldGroup label="Shipping labels">
              <Toggle
                name="labelsRequired"
                label={
                  <span className="font-medium antialiased">
                    Labels not yet printed
                  </span>
                }
                checked={filterShipmentsWithoutLabels}
                onChange={setFilterShipmentsWithoutLabels}
              />
            </PopoverFieldGroup>
          </div>
          {numFiltersEnabled ? (
            <div className="px-3">
              <Button mode="text" onClick={resetFilters}>
                Reset all filters
              </Button>
            </div>
          ) : null}
        </div>
        {organization?.externalShipmentManagementEnabled ? (
          <div className="mb-6" data-testid="filter-shipments-carriers">
            <PopoverFieldGroup label="Show selected carriers">
              {carriers?.map((carrier) => (
                <div
                  key={`carrier-filter-${carrier.carrierCode}`}
                  className="flex items-center pb-2 last:pb-0"
                >
                  <Checkbox.Root
                    className="flex h-4 w-4 items-center justify-center rounded border p-2 opacity-20 data-state-checked:border-brandDark data-state-checked:bg-brandDark data-state-checked:text-primaryInverted data-state-checked:opacity-100"
                    data-testid={`carrier-filter-checkbox-${carrier.carrierCode}`}
                    checked={!!carriersFilter.has(carrier.carrierCode)}
                    onCheckedChange={() => {
                      const newCarriersFilter = new Set(carriersFilter);
                      if (newCarriersFilter.has(carrier.carrierCode)) {
                        newCarriersFilter.delete(carrier.carrierCode);
                      } else {
                        newCarriersFilter.add(carrier.carrierCode);
                      }

                      setCarriersFilter(newCarriersFilter);
                    }}
                  >
                    <Checkbox.Indicator>
                      <IoMdCheckmark />
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                  <span className="flex ml-2">
                    <Shipment.ExternalCarrierLogo
                      carrierCode={carrier.carrierCode}
                    />
                  </span>
                </div>
              ))}
            </PopoverFieldGroup>
          </div>
        ) : null}
        <div className="mb-6">
          <PopoverFieldGroup label="Collection date">
            <DateFilter
              value={collectionDate}
              className="max-h-[calc(100vh-500px)] overflow-auto"
              id="collectionDate"
              onChange={setCollectionDate}
            />
          </PopoverFieldGroup>
        </div>
        <div className="mb-6">
          <PopoverFieldGroup label="Delivery date">
            <DateFilter
              value={deliveryDate}
              id="deliveryDate"
              className="max-h-[calc(100vh-500px)] overflow-auto"
              onChange={setDeliveryDate}
            />
          </PopoverFieldGroup>
        </div>
        <div className="mb-6">
          <PopoverFieldGroup label="Imported on">
            <DateFilter
              value={createdAt}
              id="createdAt"
              className="max-h-[calc(100vh-580px)] overflow-auto"
              onChange={setCreatedAt}
              allowFutureDates={false}
            />
          </PopoverFieldGroup>
        </div>
        <div className="mb-2">
          <PopoverFieldGroup label="Collection location">
            <CollectionLocationsSelector
              locations={collectionLocations}
              selectedLocation={selectedLocation}
              onChange={onSelectLocation}
            />
          </PopoverFieldGroup>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const SortShipmentsControls = () => {
  const {
    setOrderByDirection,
    setOrderByField,
    orderByField,
    orderByDirection,
  } = useSortShipments();

  return (
    <Popover>
      <PopoverTrigger className="w-full border-transparent p-0">
        <Button
          className="w-full text-ellipsis whitespace-nowrap rounded-full pr-5 pl-4"
          s="smaller"
          color="neutral"
          mode="text"
          icon={Icons.sort}
          onClick={() => undefined}
        >
          <span className="pl-0.5">Sort</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent variant="dropdown" className="mr-8">
        <div className="w-60">
          <PopoverFieldGroup label="Sort by" />

          <RadioGroupRoot
            defaultValue={orderByField}
            onValueChange={setOrderByField}
          >
            {ORDER_BY_OPTIONS.map((option) => (
              <RadioGroupItem
                key={option.value}
                value={option.value}
                className="flex w-full items-center py-2 px-3 text-secondary hover:bg-slate2 hover:text-primary data-state-checked:font-medium data-state-checked:text-primary md:max-w-none"
              >
                <span className="antialiased">{option.label}</span>
              </RadioGroupItem>
            ))}
          </RadioGroupRoot>
          <div className="pb-4" />
          <RadioGroupRoot
            defaultValue={orderByDirection}
            onValueChange={setOrderByDirection}
          >
            {ORDER_DIRECTION_OPTIONS.map((direction) => (
              <RadioGroupItem
                key={direction}
                value={direction}
                className={cs(
                  'flex w-full items-center py-2 px-3 text-secondary hover:bg-slate2 hover:text-primary data-state-checked:font-medium data-state-checked:text-primary md:max-w-none',
                )}
                showIndicator={false}
              >
                <div className="w-3">
                  {direction === OrderDirection.Asc ? (
                    <IoArrowUp />
                  ) : (
                    <IoArrowDown />
                  )}
                </div>
                <div className="text-md pl-3 leading-none antialiased">
                  {getDirectionLabelForField(orderByField, direction)}
                </div>
              </RadioGroupItem>
            ))}
          </RadioGroupRoot>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const SortShipments = () => {
  const { numFiltersEnabled } = useSortShipments();

  return (
    <div
      className={twMerge(
        'flex w-full rounded-lg border-l-2 border-r-2 border-primary border-l-transparent bg-primary py-[0.1rem] shadow-sm',
        numFiltersEnabled ? 'border-textPrimary border-2 py-0' : '',
      )}
    >
      <FilterShipmentsControls />
      <div className="h-full border-r border-slate6 px-0.5" />
      <SortShipmentsControls />
    </div>
  );
};
