import { Temporal } from '@js-temporal/polyfill';
import cs from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {
  isDateAfter,
  isDateBefore,
  toJSDateUTC,
  toOptionalPlainDate,
  toPlainDate,
} from '@packfleet/datetime';
import { addDays } from 'date-fns';

export type Props = {
  id: string;
  timezone: string;
  className?: string;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  disabledWhen?: (d: Temporal.PlainDate) => boolean;
} & (
  | {
      mode: 'default';
      value?: Temporal.PlainDate;
      onChangeRange?: undefined;
      onChange?: (date: Temporal.PlainDate | undefined) => void;
    }
  | {
      mode: 'range';
      value:
        | {
            from: Temporal.PlainDate | undefined;
            to?: Temporal.PlainDate | undefined;
          }
        | undefined;
      onChange?: undefined;
      onChangeRange?: (
        dateRange:
          | {
              from: Temporal.PlainDate | undefined;
              to?: Temporal.PlainDate | undefined;
            }
          | undefined,
      ) => void;
    }
);

const Calendar: FunctionComponent<Props> = ({
  id,
  value,
  minDate,
  maxDate,
  className,
  onChange,
  onChangeRange,
  disabledWhen,
  timezone,
  mode = 'default',
}) => {
  const isDisabled = useCallback(
    (d: Date): boolean => {
      const date = toPlainDate(d);
      if (disabledWhen && disabledWhen(date)) {
        return true;
      } else if (minDate && isDateBefore(date, minDate)) {
        return true;
      } else if (maxDate && isDateAfter(date, maxDate)) {
        return true;
      }
      return false;
    },
    [minDate, maxDate, disabledWhen],
  );

  const [_range, _setRange] = useState({
    from: new Date(),
    to: addDays(new Date(), 4),
  });

  return (
    <div className="relative" id={id}>
      {/* DayPicker is not typed to correctly support the `mode` prop */}
      {/*@ts-ignore */}
      <DayPicker
        className={cs('mb-8 flex content-center leading-none', className)}
        selected={
          // We can't use `plainDateToJSDate` here, because DayPicker does internal date comparison
          // to figure out which day should be highlighted, and this doesn't work if you pass it a date
          // with a time set to midnight. If we set it to 12:00 instead, it will work in any timezone.
          // In other words, the safety of Temporal can only take us this far.
          value
            ? 'toZonedDateTime' in value
              ? toJSDateUTC(
                  value.toZonedDateTime({
                    timeZone: timezone,
                    plainTime: '12:00',
                  }),
                )
              : {
                  from: value.from
                    ? toJSDateUTC(
                        value.from.toZonedDateTime({
                          timeZone: timezone,
                          plainTime: '12:00',
                        }),
                      )
                    : undefined,
                  to: value.to
                    ? toJSDateUTC(
                        value.to.toZonedDateTime({
                          timeZone: timezone,
                          plainTime: '12:00',
                        }),
                      )
                    : undefined,
                }
            : undefined
        }
        disabled={isDisabled}
        weekStartsOn={1}
        showOutsideDays
        mode={mode}
        onDayClick={
          mode === 'default' && onChange
            ? (date: Date | undefined, { disabled }) => {
                if (disabled) return;
                if (date) {
                  onChange(toPlainDate(date));
                }
              }
            : undefined
        }
        onSelect={
          mode === 'range' && onChangeRange
            ? (dateRange: DateRange | undefined) => {
                if (dateRange) {
                  onChangeRange({
                    from: toOptionalPlainDate(dateRange.from),
                    to: toOptionalPlainDate(dateRange.to),
                  });
                }
              }
            : undefined
        }
      />
    </div>
  );
};

export default Calendar;
