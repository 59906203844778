import { Button, Colors } from '@packfleet/ui';
import cs from 'classnames';
import { FunctionComponent, ReactNode, useState } from 'react';
import { IoFilter } from 'react-icons/io5';
import ReactSelect, { SingleValue, StylesConfig } from 'react-select';
import { CollectionLocationFragment } from '../../generated/graphql';
import { formatCollectionLocation } from '../../utilities/collection-locations';
import OnClickOutside from '../OnClickOutside';

export type Props = {
  className?: string;
  locations: CollectionLocationFragment[];
  selectedLocation: CollectionLocationFragment | null;
  onChange: (location: CollectionLocationFragment | null) => void;
};

type DropdownProps = {
  className?: string;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
  children?: ReactNode;
};

const Dropdown = ({
  className,
  children,
  isOpen,
  target,
  onClose,
}: DropdownProps) => (
  <div className={cs(className, 'relative')}>
    {target}
    {isOpen ? (
      <OnClickOutside action={onClose}>
        <div className="absolute right-0 z-10 mt-3 rounded bg-primary shadow">
          {children}
        </div>
      </OnClickOutside>
    ) : null}
  </div>
);

const selectStyles: StylesConfig<Option, false> = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 240,
    minHeight: '44px',
    borderColor: state.menuIsOpen ? Colors.neutral : Colors.borderPrimary,
    boxShadow: state.menuIsOpen ? `0 0 0 1px ${Colors.neutral}` : undefined,
    '&:hover': {
      borderColor: state.menuIsOpen ? undefined : Colors.borderSecondary,
    },
  }),
  menuList: () => ({
    minWidth: 240,
    margin: 0,
    maxHeight: 240,
    overflowY: 'auto',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? Colors.white
      : state.isDisabled
        ? Colors.faded
        : Colors.neutral,
    backgroundColor:
      state.isFocused && !state.isSelected
        ? Colors.bgSecondary
        : state.isSelected
          ? Colors.info
          : Colors.white,
    '&:active': {
      backgroundColor: Colors.faded,
    },
  }),
};

const selectAllOption = { label: 'Select All', value: '' };
type Option = { label: string; value: string };

const isSelectAllValue = (val: Option | null) =>
  val == null || val?.value === '';
const locationToOption = (location: CollectionLocationFragment) => ({
  label: formatCollectionLocation(location),
  value: location.id,
});

const CollectionLocationsSelector: FunctionComponent<Props> = ({
  className,
  locations,
  selectedLocation,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const locationOptions = locations.map((l) => locationToOption(l));
  const options: Option[] = [selectAllOption, ...locationOptions];

  const relayOnChange = (val: SingleValue<Option>) => {
    const newLocation = locations.find((l) => l.id === val?.value);
    if (newLocation != null) {
      onChange(newLocation);
    } else {
      onChange(null);
    }
    setIsOpen(false);
  };

  // If there is one location selected then use this as the selected location
  // otherwise assume all locations are selected
  const selectedOption =
    selectedLocation != null
      ? locationToOption(selectedLocation)
      : selectAllOption;

  return (
    <Dropdown
      className={className}
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <Button
          className="w-full text-ellipsis whitespace-nowrap rounded-full"
          s="small"
          color="neutral"
          mode={isOpen ? 'primary' : 'outline'}
          icon={IoFilter}
          onClick={toggleOpen}
        >
          {isSelectAllValue(selectedOption)
            ? `All locations`
            : selectedOption.label}
        </Button>
      }
    >
      <ReactSelect
        autoFocus
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        tabSelectsValue={false}
        closeMenuOnSelect={false}
        menuIsOpen
        placeholder="Search..."
        options={options}
        styles={selectStyles}
        value={selectedOption}
        onChange={relayOnChange}
        getOptionLabel={(o: Option) =>
          isSelectAllValue(o) ? 'All locations' : o.label
        }
        getOptionValue={(o: Option) => (isSelectAllValue(o) ? '' : o.value)}
      />
    </Dropdown>
  );
};

export default CollectionLocationsSelector;
