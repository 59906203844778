import { FunctionComponent } from 'react';
import { IoHome } from 'react-icons/io5';
import { Routes, route } from '../../utilities/routes';
import SidebarButton from './SidebarButton';

export type Props = {
  activeTitle?: string;
};

const Sidebar: FunctionComponent<Props> = ({ activeTitle }) => {
  const buttons = [
    {
      title: 'Home',
      icon: IoHome,
      href: route(Routes.appDashboard),
    },
    // TODO: Disabled for Shopify App Review
    // {
    //   title: "Settings",
    //   icon: IoCog,
    //   href: route(Routes.appSettings),
    // },
  ];
  return (
    <nav className="h-full border-r border-primary bg-primary">
      {buttons.map((b) => {
        return (
          <SidebarButton
            key={b.title}
            icon={b.icon}
            href={b.href}
            isActive={activeTitle === b.title}
          >
            {b.title}
          </SidebarButton>
        );
      })}
    </nav>
  );
};

export default Sidebar;
