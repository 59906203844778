import cs from 'classnames';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { Button } from '@packfleet/ui';
import { IconType } from 'react-icons';
import { IoTimeOutline } from 'react-icons/io5';
import OnClickOutside from '../OnClickOutside';
import Input from '../input/Input';
import FormElement from './FormElement';

const CollapsibleFieldContext = React.createContext<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => undefined,
});

export const useCollapsibleField = () => {
  const context = useContext(CollapsibleFieldContext);

  return context;
};

export type CollapsibleFieldProps = {
  placeholder?: string;
  label?: string;
  className?: string;
  value?: string;
  icon?: IconType;
  cta?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  collapsedContent?: ReactNode;
  onClose?: () => void;
};

export const CollapsibleField = ({
  placeholder,
  value,
  icon: Icon = IoTimeOutline,
  children,
  cta,
  label,
  className,
  disabled,
  collapsedContent,
  onClose,
}: CollapsibleFieldProps) => {
  const [open, setOpen] = useState(false);

  return (
    <FormElement label={label} className={cs('relative', className)}>
      <>
        <Input
          type="text"
          readOnly
          className="relative w-full"
          value={value}
          placeholder={placeholder}
          onClick={() => !disabled && setOpen(true)}
          disabled={disabled}
        />
        <div
          className="pointer-events-none absolute inset-y-1/2 right-0 mr-4 h-6 w-6 opacity-40"
          style={{ transform: label ? 'translateY(-20%)' : 'translateY(-55%)' }}
        >
          <Icon strokeWidth={2} size={24} color="currentColor" />
        </div>
        {open ? (
          <OnClickOutside action={() => setOpen(false)}>
            <div className="absolute -left-3 -right-3 -top-3 z-10 flex-1 rounded-lg shadow-lg">
              <div
                className={cs('pointer-events-none mt-6 mb-2', {
                  'h-14': label,
                  'h-10': !label,
                })}
              />
              <div className="rounded-lg bg-primary p-4 pt-2">
                <CollapsibleFieldContext.Provider
                  value={{
                    open,
                    setOpen,
                  }}
                >
                  <>
                    {children}
                    <div className="flex justify-end">
                      {cta}
                      <Button
                        s="smaller"
                        className="ml-3 text-center"
                        onClick={() => {
                          if (onClose) {
                            onClose();
                          }

                          setOpen(false);
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </>
                </CollapsibleFieldContext.Provider>
              </div>
            </div>
          </OnClickOutside>
        ) : (
          collapsedContent
        )}
      </>
    </FormElement>
  );
};
