import {
  formatDateForTracking,
  toPlainDate,
  todayLocal,
} from '@packfleet/datetime';
import React from 'react';
import { IoCalendarClearOutline } from 'react-icons/io5';

import Calendar, {
  Props as CalendarProps,
} from '@/components/date-picker/Calendar';
import {
  CollapsibleField,
  useCollapsibleField,
} from '@/components/form/CollapsibleField';
import { useOrganizationTimezone } from '@/hooks/timezone';
import { formatDate } from '@/utilities/date';
import { Temporal } from '@js-temporal/polyfill';
import { Button } from '@packfleet/ui';

type FilterCalendarProps = {
  onChange: CalendarProps['onChange'];
  id: string;
  timezone: string;
  value: Temporal.PlainDate | undefined;
  allowFutureDates?: boolean;
};

const FilterCalendar = ({
  onChange,
  id,
  timezone,
  value,
  allowFutureDates,
}: FilterCalendarProps) => {
  const { setOpen } = useCollapsibleField();
  const today = todayLocal(timezone);

  return (
    <Calendar
      id={id}
      mode="default"
      timezone={timezone}
      value={value}
      maxDate={allowFutureDates ? undefined : today}
      onChange={(date) => {
        onChange?.(date);
        setOpen(false);
      }}
    />
  );
};

type ClearButtonProps = {
  onClick: () => void;
};

const ClearButton = ({ onClick }: ClearButtonProps) => {
  const { setOpen } = useCollapsibleField();
  return (
    <Button
      s="smaller"
      className="mr-auto"
      onClick={() => {
        onClick();
        setOpen(false);
      }}
    >
      Clear
    </Button>
  );
};

type DateFilterProps = {
  id: string;
  value?: string;
  onChange: (date: string | undefined) => void;
  allowFutureDates?: boolean;
  className?: string;
};

export const DateFilter = ({
  id,
  value,
  onChange,
  allowFutureDates = true,
  className,
}: DateFilterProps) => {
  const timezone = useOrganizationTimezone();
  const selectedDate = value ? toPlainDate(new Date(value)) : undefined;

  return (
    <CollapsibleField
      placeholder="Any date"
      label=""
      value={
        selectedDate
          ? formatDateForTracking(selectedDate, timezone, {
              lowercase: false,
            })
          : ''
      }
      icon={IoCalendarClearOutline}
      cta={value ? <ClearButton onClick={() => onChange(undefined)} /> : null}
    >
      <div className={className}>
        <FilterCalendar
          id={id}
          value={selectedDate}
          onChange={(value) => {
            if (!value) return;

            onChange(formatDate(value));
          }}
          allowFutureDates={allowFutureDates}
          timezone={timezone}
        />
      </div>
    </CollapsibleField>
  );
};
